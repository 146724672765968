
import { Options } from "vue-class-component";
import NodesMinxins from "../NodesMixins";

@Options({
    name: "flowable-nodes-root-node",
    emits: ["selected", "insertNode"],
})
export default class RootNode extends NodesMinxins {
    private showError = false;
    private errorInfo = "";

    get content() {
        let texts: any = [];
        const config = this.config.props;
        if (config.assignedOrg && config.assignedOrg.length > 0) config.assignedOrg.forEach((item: any) => texts.push(item.title));
        if (config.assignedDept && config.assignedDept.length > 0) config.assignedDept.forEach((item: any) => texts.push(item.title));
        if (config.assignedPost && config.assignedPost.length > 0) config.assignedPost.forEach((item: any) => texts.push(item.title));
        if (config.assignedUser && config.assignedUser.length > 0) config.assignedUser.forEach((item: any) => texts.push(item.title));
        if (texts.length > 0) {
            return String(texts).replaceAll(",", "、");
        } else {
            return "所有人";
        }
    }
    get headerBgc() {
        if (this.diagramMode === "viewer") {
            return this.config.props.headerBgc;
        } else {
            return "#576a95";
        }
    }
    //校验数据配置的合法性
    public validate(err: any) {
        try {
            this.showError = false;
            if (this.formItems.length !== this.config.props.formPerms.length) {
                this.errorInfo = "表单审批节点权限部分缺失：【请打开审批流程配置审批节点】";
                err.push("表单审批节点权限部分缺失：【请打开审批流程配置审批节点】");
                this.showError = true;
            }
            return !this.showError;
        } catch (e) {
            return true;
        }
    }
}
